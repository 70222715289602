<template>
  <div class="manage-project-booking__booked-by-other">
    <div>
      <booked-by-other-booking-table
        :getBookingsAPI="bookingAPI.getBookings"
        :approveBookingAPI="bookingAPI.approveBooking"
        :rejectBookingAPI="bookingAPI.rejectBooking"
        :cancelBookingAPI="bookingAPI.cancelBooking"
        detailRouteName="ManageProjectBookingDetail"
      ></booked-by-other-booking-table>
    </div>
  </div>
</template>

<script>
import BookedByOtherBookingTable from "@/components/GlobalComponents/Shared/ProjectSales/Booking/BookedByOtherBookingTable";
import { projectBooking as bookingAPI } from "@/api";

export default {
  components: { BookedByOtherBookingTable },
  mixins: [],
  props: {},
  data: function () {
    return {
      bookingAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
